import React from 'react'
import { Stack, styled } from '@centrito/ui/src'
import { DetailText1 } from '@centrito/ui/src/components/text/new/detail/DetailText1'

export interface PercentageDiscountProps {
  productDiscount: number
}

const Container = styled(Stack, {
  backgroundColor: '$brightRed',
  borderTopLeftRadius: 8,
  padding: 3,
  marginLeft: 6,
  marginTop: 7,
  borderBottomRightRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
})

export const PercentageDiscount: React.FC<PercentageDiscountProps> = ({ productDiscount }) => {
  if (productDiscount === 0) {
    return null
  }

  return (
    <Container>
      <DetailText1 color="$pureWhite" fontWeight="700">
        -{Math.trunc(productDiscount)}%
      </DetailText1>
    </Container>
  )
}
