import { z } from 'zod'

export const ColorLibrarySchema = z.object({
  key: z.string(),
  name: z.string(),
  hex: z.string(),
})

export type ColorLibrary = z.infer<typeof ColorLibrarySchema>

export const COLOR_LIBRARY: ColorLibrary[] = [
  {
    key: 'rojo_hindu',
    name: 'Rojo Hindú',
    hex: '#882D17',
  },
  {
    key: 'rojo_carmesi',
    name: 'Rojo Carmesí',
    hex: '#D3202F',
  },
  {
    key: 'rojo',
    name: 'Rojo',
    hex: '#FE2712',
  },
  {
    key: 'rojo_escarlata',
    name: 'Rojo Escarlata',
    hex: '#FE3D0D',
  },
  {
    key: 'rojo_encendido',
    name: 'Rojo Encendido',
    hex: '#FD5308',
  },
  {
    key: 'naranja_fuego',
    name: 'Naranja Fuego',
    hex: '#FC7605',
  },
  {
    key: 'naranja',
    name: 'Naranja',
    hex: '#FB9902',
  },
  {
    key: 'naranja_melon',
    name: 'Naranja Melón',
    hex: '#FBAB02',
  },
  {
    key: 'naranja_ambar',
    name: 'Naranja Ámbar',
    hex: '#FABC02',
  },
  {
    key: 'dorado',
    name: 'Dorado',
    hex: '#efb810 ',
  },
  {
    key: 'mostaza',
    name: 'Mostaza',
    hex: '#e9bd15',
  },
  {
    key: 'amarillo_dorado',
    name: 'Amarillo Dorado',
    hex: '#FCDD1B',
  },
  {
    key: 'amarillo',
    name: 'Amarillo',
    hex: '#FFFF00',
  },
  {
    key: 'amarillo_lima',
    name: 'Amarillo Lima',
    hex: '#E7F42F',
  },
  {
    key: 'amarillo_cartujo',
    name: 'Amarillo Cartujo',
    hex: '#D0EA2B',
  },
  {
    key: 'verde_olivo',
    name: 'Verde Olivo',
    hex: '#9BCD2F',
  },
  {
    key: 'verde',
    name: 'Verde',
    hex: '#66B032',
  },
  {
    key: 'verde_militar',
    name: 'Verde Militar',
    hex: '#78866b',
  },
  {
    key: 'verde_botella',
    name: 'Verde Botella',
    hex: '#343b29',
  },
  {
    key: 'verde_encendido',
    name: 'Verde Encendido',
    hex: '#34A180',
  },
  {
    key: 'verde_menta',
    name: 'Verde Menta',
    hex: '#9BFAB0',
  },
  {
    key: 'turquesa',
    name: 'Turquesa',
    hex: '#5dc1b9',
  },
  {
    key: 'azul_verdoso',
    name: 'Azul Verdoso',
    hex: '#0392CE',
  },
  {
    key: 'azul_cielo',
    name: 'Azul Cielo',
    hex: '#036DE6',
  },
  {
    key: 'azul',
    name: 'Azul',
    hex: '#0247FE',
  },
  {
    key: 'azul_indigo',
    name: 'Azul Índigo',
    hex: '#2024D1',
  },
  {
    key: 'morado',
    name: 'Morado',
    hex: '#572364',
  },
  {
    key: 'purpura',
    name: 'Purpura',
    hex: '#3D01A4',
  },
  {
    key: 'violeta',
    name: 'Violeta',
    hex: '#4c2882',
  },
  {
    key: 'purpura_berenjena',
    name: 'Purpura Berenjena',
    hex: '#800080',
  },
  {
    key: 'fucsia',
    name: 'Fucsia',
    hex: '#e30052 ',
  },
  {
    key: 'cafe_chocolate',
    name: 'Café Chocolate',
    hex: '#804000',
  },
  {
    key: 'cafe',
    name: 'Café',
    hex: '#592E0B',
  },
  {
    key: 'marron_oscuro',
    name: 'Marrón Oscuro',
    hex: '#562b05',
  },
  {
    key: 'marron_claro',
    name: 'Marrón Claro',
    hex: '#c39055',
  },
  {
    key: 'cafe_gamuza',
    name: 'Café Gamuza',
    hex: '#D29340',
  },
  {
    key: 'cafe_caqui',
    name: 'Café Caqui',
    hex: '#E6B57E',
  },
  {
    key: 'cafe_beige',
    name: 'Café Beige',
    hex: '#E8C39E',
  },
  {
    key: 'lila',
    name: 'Lila',
    hex: '#deb4cb',
  },
  {
    key: 'rosa',
    name: 'Rosa',
    hex: '#ffCBDB',
  },
  {
    key: 'rojo_pastel',
    name: 'Rojo Pastel',
    hex: '#FEA3AA',
  },
  {
    key: 'salmon',
    name: 'Salmón',
    hex: '#e7968b',
  },
  {
    key: 'naranja_pastel',
    name: 'Naranja Pastel',
    hex: '#F8B88B',
  },
  {
    key: 'blanco',
    name: 'Blanco',
    hex: '#FFFFFF',
  },
  {
    key: 'amarillo_pastel',
    name: 'Amarillo Pastel',
    hex: '#FAF884',
  },
  {
    key: 'verde_pastel',
    name: 'Verde Pastel',
    hex: '#BAED91',
  },
  {
    key: 'azul_pastel',
    name: 'Azul Pastel',
    hex: '#B2CEFE',
  },
  {
    key: 'purpura_pastel',
    name: 'Purpura Pastel',
    hex: '#F2A2E8',
  },
  {
    key: 'rosa_pastel',
    name: 'Rosa Pastel',
    hex: '#ffb6c1',
  },
  {
    key: 'rosa_palido',
    name: 'Rosa Palido',
    hex: '#f2dcdb',
  },
  {
    key: 'camel',
    name: 'Camel',
    hex: '#bf8a3d',
  },
  {
    key: 'marfil',
    name: 'Marfil',
    hex: '#E2D9C4',
  },
  {
    key: 'plateado',
    name: 'Plateado',
    hex: '#bebebe',
  },
  {
    key: 'gris',
    name: 'Gris',
    hex: '#8C8C8C',
  },
  {
    key: 'gris_carbon',
    name: 'Gris Carbón',
    hex: '#555555',
  },
  {
    key: 'negro',
    name: 'Negro',
    hex: '#000000',
  },
  {
    key: 'transparente',
    name: 'Transparente',
    hex: '#ffffff',
  },
  {
    key: 'sujeto_a_disponibilidad',
    name: 'Sujeto a Disponibilidad',
    hex: '#ffffff',
  },
  {
    key: 'multicolor',
    name: 'Multicolor',
    hex: '#ffffff',
  },
]
