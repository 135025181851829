import React from 'react'
import { XStack } from 'tamagui'
import { ContentBoldText, ContentStandardText } from '@centrito/ui/src/components/text/new/content'
import { FootnoteAccentText } from '@centrito/ui/src/components/text/new/footnote'

export interface DiscountTagProps {
  logo: React.ReactNode
  logoBackgroundColor?: string
  discount: number
  size?: 'small' | 'big'
}

export const DiscountTag: React.FC<DiscountTagProps> = ({
  logo,
  logoBackgroundColor = '$pureWhite',
  discount,
  size = 'small',
}) => {
  return (
    <XStack
      backgroundColor="#ffd148"
      overflow="hidden"
      borderRadius={5}
      flexGrow={0}
      flexShrink={1}
      $platform-web={{
        width: 'fit-content',
      }}
      alignSelf="flex-start"
    >
      <XStack
        backgroundColor={logoBackgroundColor}
        borderWidth={1}
        borderColor="$neutral400"
        paddingHorizontal={6}
        borderRadius={5}
        paddingVertical={3.5}
        alignItems="center"
        margin="auto"
        flexShrink={0}
      >
        {logo}
      </XStack>
      {discount > 0 && (
        <XStack paddingHorizontal={5} paddingVertical={5} marginVertical="auto">
          {size === 'big' ? (
            <ContentStandardText color="$deepShopperBlue">
              {' '}
              OUTLET <ContentBoldText color="$deepShopperBlue">-{discount}%</ContentBoldText>
            </ContentStandardText>
          ) : (
            <FootnoteAccentText color="$deepShopperBlack">-{discount}%</FootnoteAccentText>
          )}
        </XStack>
      )}
    </XStack>
  )
}
