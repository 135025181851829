import React from 'react'
import { useWindowDimensions } from 'tamagui'
import { Stack, XStack, YStack } from 'tamagui'
import { BaseSkeleton } from '@centrito/ui/src/components/BaseSkeleton'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

const FeedFilterBar: React.FC = () => {
  const { width } = useWindowDimensions()
  const cardWidth =
    (width > MOBILE_WIDTH_STYLES.maxWidth ? MOBILE_WIDTH_STYLES.maxWidth : width) / 3 - 16
  const cardHeight = cardWidth * 0.6
  return (
    <React.Fragment>
      <YStack marginTop={16}>
        <XStack justifyContent="space-between" alignItems="center">
          <BaseSkeleton height={30} width={width * 0.4} />
          <BaseSkeleton height={24} width={width * 0.2} />
        </XStack>

        <Stack flexDirection="row" flexWrap="wrap">
          {Array.from({ length: 6 }).map((_, index) => (
            <BaseSkeleton
              height={cardHeight}
              width={cardWidth}
              key={`filter-tree-skeelton${index}`}
            />
          ))}
        </Stack>
        <XStack
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="#FFFFFF"
          paddingVertical={8}
          overflow="hidden"
        >
          <BaseSkeleton width={94} height={31} borderRadius={24} />
          <BaseSkeleton width={70} height={31} borderRadius={24} />
          <BaseSkeleton width={210} height={31} borderRadius={24} />
        </XStack>
      </YStack>
    </React.Fragment>
  )
}

export default FeedFilterBar
