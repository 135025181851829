import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { IMAGE_CODE_FROM_PNG_PIXEL_GRAY } from '@centrito/common/constants'
import { Stack } from '@centrito/ui/src'

export interface ProductImageProps {
  variantImage: string
  width: number
  height: number
  alt: string
  placeholder: string
}

export const ProductImage: React.FC<ProductImageProps> = ({ variantImage, width, height, alt }) => {
  return (
    <Stack height={height} width={width}>
      <Image
        fill
        src={variantImage}
        alt={alt}
        contentFit="cover"
        style={{ borderRadius: 8 }}
        sizes="50vw"
        placeholder="blur"
        blurDataURL={IMAGE_CODE_FROM_PNG_PIXEL_GRAY}
      />
    </Stack>
  )
}
